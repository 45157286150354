export enum ParamEnum {
  Template = 'template',
  Project = 'project',
  EditMode = 'editMode',
  Id = 'id',
  Status = 'status',
  License = 'license',
  Group = 'group',
  Option = 'option',
  Type = 'type',
  Cat = 'cat',
  Sort = 'sort',
  UserOnly = 'useronly',
  Unid = 'unid',
  UnidList = 'unidList',
  Parent = 'parent',
  Key = 'key',
  TaskType = 'taskType',
  RepId = 'repId',
  Target = 'target',
  Offline = 'offline',
  State = 'state',
  Server = 'server',
  Limit = 'limit',
  Offset = 'offset',
  Class = 'class',
  Votes = 'votes',
  Employee = 'employee',
  MailOnly = 'mailOnly',
  NoForm = 'noform',
  AnonymousUse = 'anonymousUse',
  Org = 'org',
  OverrideSent = 'override-sent',
  Reminder = 'reminder',
  Include = 'include',
  ZipStart = 'zipstart',
  CityStart = 'citystart',
  System = 'system',
  Responses = 'responses',
  Query = 'query',
  RenewPassword = 'renewPassword',
  IncidentType = 'incident-type',
  Login = 'login',
  Ownership = 'ownership'
}
