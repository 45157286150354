export enum RouteEnum {
  Home = '/',
  Sub = 'sub',
  SubDocuments = 'sub-documents',
  SubProjects = 'sub-projects',
  Management = 'management',
  Versions = 'versions',
  QaDocuments = 'qadocuments',
  RiskAssessments = 'risk-assessments',
  RiskTypes = 'risk-types',
  Settings = 'settings',
  Departments = 'departments',
  Documents = 'documents',
  DocTypes = 'doc-types',
  Employees = 'employees',
  Notes = 'notes',
  Companies = 'companies',
  CompanyCategories = 'company-categories',
  Categories = 'categories',
  Organization = 'organization',
  Users = 'users',
  UserGroups = 'user-groups',
  Projects = 'projects',
  Types = 'types',
  Incidents = 'incidents',
  Contacts = 'contacts',
  MailingList = 'mailing-list',
  CompetenceTemplates = 'competence-templates',
  Forms = 'forms',
  Tasks = 'tasks',
  Templates = 'templates',
  Web = 'web',
  News = 'news',
  All = 'all',
  My = 'my',
  Help = 'help',
  Search = 'search',
  Correspondence = 'correspondence',
  Profiles = 'profiles',
  Auth = 'auth',
  P = 'p', // redirectTo /profiles
  NewsletterSettings = 'newsletter-settings',
  NewsletterTemplates = 'newsletter-templates',
  Calendar = 'calendar',
  Newsletters = 'newsletters',
  Invitation = 'invitation',
  Password = 'password',
  Change = 'change',
  Reports = 'reports',
  Add = 'add',
  Workflows = 'workflows',
  Competence = 'competence',
  Folders = 'folders',
  Dashboard = 'dashboard',
  Fill = 'fill',
  New = 'new',
  Forgot = 'forgot',
  Data = 'data',
  Todo = 'todo',
  Proposal = 'proposal',
  Members = 'members',
  Registrations = 'registrations',
  Sja = 'sja',
  Office = 'office',
  Agreements = 'agreements',
  Senders = 'senders',
  Dispatches = 'dispatches',
  Sharepoint = 'sharepoint',
  Jobs = 'jobs',
  Media = 'media',
  Menus = 'menus',
  Keywords = 'keywords',
  Deviations = 'deviations',
  Lifetimes = 'lifetimes',
  Books = 'books',
  AgreementKeywords = 'agreement-keywords',
  SyncTasks = 'sync-tasks',
  ServiceReports = 'service-reports',
  Rauma = 'rauma'
}
