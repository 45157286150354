export enum KeyEnum {
  FormUnidReplace = '[formUnid]',
  UnidReplace = '[unid]',
  MsGraphToken = 'msGraphToken',
  WidgetListId = 'widget-list',
  WidgetIdPrefix = 'widget-',
  Anonymous = 'Anonymous',
  ResizeEvent = 'resize',
  KeyPressEvent = 'keypress',
  Company = 'company',
  Person = 'person',
  Language = 'language',
  Search = 'search',
  CurrentUser = 'currentUserV2',
  Profile = 'profileV2',
  Name = 'name',
  Recent = 'recent',
  OfflineDataSet = 'offlineDataSet',
  Timestamp = 'timestamp'
}

export enum ModuleEnum {
  JobModule = 'jobModule',
  ContactsModule = 'contactsModule',
  DocumentModule = 'documentModule',
  FormModule = 'formModule',
  IncidentModule = 'incidentModule',
  ProjectModule = 'projectModule',
  QadocModule = 'qadocModule',
  TaskModule = 'taskModule',
  WebModule = 'webModule',
  OrganizationModule = 'organizationModule'
}

export enum ValueEnum {
  IBricks = 'ibricks',
  AgjerdeOrg = 'agjerde',
  NordveggenOrg = 'nordveggen',
  TestOrg = 'test',
  Today = 'today',
  ShowCondition = 'showCondition',
  ShowAlways = 'showAlways',
  Desc = 'desc',
  Asc = 'asc',
  No = 'no',
  En = 'en',
  Btw = 'btw',
  Group = 'group',
  Read = 'read',
  Write = 'write',
  MoveDocsKeepRelationNew = 'MoveDocsKeepRelationNew',
  MoveDocsDeleteRelationNew = 'MoveDocsDeleteRelationNew',
  Internal = 'internal',
  Marketing = 'marketing',
  After = 'after',
  Web = 'web',
  All = 'all',
  Delete = 'delete',
  Actions = 'actions',
  NorwayCode = 'NO',
  IncidentsDb = 'Hendelser',
  ProjManager = 'projManager',
  Localhost = 'localhost',
  Isend = 'isend',
  Open = 'open',
  VersionReady = 'VERSION_READY'
}
