export const REG_EXP = Object.freeze({
  api: /https:\/\/api.(.+).no/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  phone: /^[0-9]{5,9}$/,
  phoneLong: /^[0-9]{5,12}$/,
  hsl: /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g,
  digits: /[0-9]/g,
  digitsOnly: /^[0-9]+$/,
  commaSpaceSeparatedDigits: /^[, +0-9]+$/,
  commaDotSpaceSeparatedDigits: /^[,. +0-9]+$/,
  spaces: / +/g,
  unid: /[A-F0-9]{32}/g,
  notWordAndUnderscore: /[\W_]+/g,
  fileNameWithoutExtension: /(.+(?=\..+$))|(.+[^.])/,
  lineBreaks: /\r\n/g,
  sixNumbers: /^[0-9]{6}$/,
  pdfFileName: /[^a-z0-9-]/gi,
  multipleUnderscores: /__+/g
});
