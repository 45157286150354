import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MatDialogConfig} from '@angular/material/dialog';
import {ICellRendererParams} from '@ag-grid-community/core';
import {v4 as uuidV4, validate as validateUuid} from 'uuid';
import {IExternalFilter} from 'scam/table';
import {hasOwnProperty} from 'utilities';
import {environment} from '../../../environments/environment';
import {ICloudfrontParams, IResize} from './layout/image-picker/cropper.directive';
import {ICellIcon, IEntityUrl} from './layout/renderers/name-renderer/name-renderer.component';
import {ACCEPTED_IMAGE_TYPES, ALL_READERS_ACCESS} from './constants/constants';
import {REG_EXP} from './constants/reg-exp';
import {IStatusDictionary} from './interfaces/i-status-data';
import {KeyEnum, ValueEnum} from './enums/key.enum';
import {TaskTypeEnum, TypeEnum} from './enums/type.enum';
import {ParamEnum} from './enums/param.enum';
import {RouteEnum} from './enums/route.enum';
import {FieldEnum} from './enums/field.enum';
import {CategoryOption, ICompany, IContact, IDialogData, IIBricksEntity, IOption, IProfile, ITask} from './interfaces';

export function isAuthenticated(): boolean {
  return environment.production ? isLtpaTokenPresent() : !!environment.username && !!environment.password;
}

export function isLtpaTokenPresent(): boolean {
  return document.cookie.includes('LtpaToken');
}

export function statusesToOptions(obj: IStatusDictionary<number | string>): IOption[] {
  return Object.keys(obj).map(statusToOption);
}

export function statusToOption(value: string): IOption {
  return {label: `STATUSES.${value}`, value};
}

export function getOrg(org: string): string {
  return org === ValueEnum.IBricks ? ValueEnum.TestOrg : org || ValueEnum.TestOrg;
}

export function getSizeInBytes(megabytes: number): number {
  return megabytes * 1024 * 1024;
}

export function getFieldName(path: string[]): string {
  return path.join('.');
}

export function dispatchResizeEvent(timeout?: number): void {
  setTimeout(() => window.dispatchEvent(new Event(KeyEnum.ResizeEvent)), timeout || 0); // wait a tick for correct rendering
}

export function isNorwayCountryCode(code: string | undefined): boolean {
  return code === ValueEnum.NorwayCode || !code;
}

export function getContactPhoneWithCode(contact: IContact): string {
  const phone = contact?.cellPhoneNumber || contact?.officePhoneNumber || contact?.phoneNumber || '';
  if (phone?.length) {
    if (isNorwayCountryCode(contact?.address?.country?.code)) {
      return phone;
    } else if (contact?.address?.country?.phoneCode?.length) {
      return `+${contact.address.country.phoneCode + phone}`;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function isNumber(num: number): boolean {
  return typeof num === TypeEnum.Number;
}

export function isString(str: unknown): boolean {
  return typeof str === TypeEnum.String;
}

export function encodeObject(value: any): string {
  return btoa(JSON.stringify(value));
}

export function decodeObject(value: string): any {
  return JSON.parse(atob(value));
}

export function removeSpaces(value: string): string {
  return value?.length ? value.replace(REG_EXP.spaces, '') : '';
}

export function deepCopy(value: any): any {
  return JSON.parse(JSON.stringify(value));
}

export function getItemField(i: any): any {
  return i?.person?.value || i?.value || i?.code || i?.id || i?.unid || i?.label || i?.subject || i;
}

/**
 * Converts url string to url commands array.
 * @param url
 * @private
 */
export function getUrlCommands(url?: string): string[] {
  return url?.split('/')?.filter(Boolean) || [];
}

export function patchTargetValueByFieldName(
  formGroup: UntypedFormGroup,
  data: any[],
  entity: any,
  fCtrlName: string
): void {
  if (formGroup && data && hasOwnProperty(entity, fCtrlName) && entity?.[fCtrlName]) {
    if (Array.isArray(entity[fCtrlName])) {
      const values =
        entity[fCtrlName]
          .filter(Boolean)
          .map(i => getItemField(i))
          .filter(Boolean) || [];
      if (values?.length) {
        const refs = data.filter(i => i && values.includes(getItemField(i))).filter(Boolean) || [];
        if (refs?.length) {
          formGroup.patchValue({[fCtrlName]: refs});
        } else {
          entity[fCtrlName].forEach((i: any) => data.push(i));
          formGroup.patchValue({[fCtrlName]: entity[fCtrlName]});
        }
      }
    } else {
      const value = getItemField(entity[fCtrlName]);
      if (value) {
        const ref = data.find(i => i && getItemField(i) === value) || null;
        if (ref) {
          formGroup.patchValue({[fCtrlName]: ref});
        } else {
          data.push(entity[fCtrlName]);
          formGroup.patchValue({[fCtrlName]: entity[fCtrlName]});
        }
      }
    }
  }
}

export function getEntityIdFromRouteParams(route: ActivatedRoute, isNotUnidFormat?: boolean): string {
  const id = route?.snapshot?.paramMap?.get(ParamEnum.Id) || null;
  if (isNotUnidFormat) {
    const filtered = [RouteEnum.Add].filter(i => id?.startsWith(i));
    return filtered?.length ? null : id;
  } else {
    return isUnid(id) || isUuid(id) ? id : null;
  }
}

export function isFillTemplate(url: string): boolean {
  const routes = [
    `/${RouteEnum.Fill}/`,
    `${RouteEnum.Password}/${RouteEnum.New}`,
    `${RouteEnum.Password}/${RouteEnum.Forgot}`,
    `${RouteEnum.Profiles}/${RouteEnum.Invitation}`,
    `${RouteEnum.Profiles}/${RouteEnum.Calendar}`,
    `${RouteEnum.Profiles}/${RouteEnum.NewsletterSettings}`,
    `${RouteEnum.P}/`
  ];
  return url?.length ? !!routes.filter(i => url?.includes(i)).length : false;
}

export function getAcceptedImageTypesString(): string {
  return ACCEPTED_IMAGE_TYPES.map(i => `image/${i}`).toString();
}

export function enumToOptionArray(obj: object, translation?: string): IOption[] {
  return Object.entries(obj).map(([label, value]) => ({value, label: translation ? translation + value : label}));
}

export function openEmail(entity: any): void {
  const mailAddress =
    Array.isArray(entity?.mailAddress) && entity?.mailAddress?.length && entity?.mailAddress[0]
      ? entity.mailAddress[0]
      : entity?.email;
  if (mailAddress?.length) {
    const win = window as any;
    const subject = entity?.person?.label || entity?.subject || '';
    win.open(`mailto:${mailAddress}?subject=${subject}`, win.chrome ? '_blank' : '_top');
  }
}

export function openPhone(params: ICellRendererParams): void {
  if (params?.value) {
    openInSameTab(`tel:${params.value}`);
  }
}

export class IBricksDialogConfig extends MatDialogConfig {
  override disableClose = true;
  override data: IDialogData = {};

  constructor(data?: IDialogData | null, config?: MatDialogConfig) {
    super();
    if (config) {
      Object.assign(this, config);
    }
    if (data) {
      Object.assign(this.data, data);
    }
  }
}

export function existingFileNameValidator(names: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let name: string = control?.value;
    if (name?.length) {
      name = control.value.toLowerCase().replace(REG_EXP.spaces, '_');
      return names?.includes(name) ? {exist: true} : null;
    } else {
      return null;
    }
  };
}

export function getIconObject(iconName: string, color?: string): ICellIcon {
  return {icon: iconName || 'bug_report', alwaysIcon: true, color};
}

export function mergeUniqOptions(targetList: any[], sourceList: any[], isSetFilteredLength?: boolean): any[] {
  const filtered: any = uniqOptions(targetList, sourceList);
  if (isSetFilteredLength) {
    filtered.__proto__.filteredLength = filtered.length; // using to display count of newly added items
  }
  return targetList?.concat(filtered);
}

export function replaceAndMergeUpdatedOptions(targetList: any[], sourceList: any[]): any[] {
  const sourceValues = sourceList?.map(i => getItemField(i));
  if (targetList?.length) {
    const replaced = targetList.map(i =>
      sourceValues.includes(getItemField(i)) ? sourceList.find(j => getItemField(j) === getItemField(i)) : i
    );
    return mergeUniqOptions(replaced, sourceList);
  } else {
    return sourceList;
  }
}

export function uniqOptions(targetList: any[], sourceList: any[]): any[] {
  const existValues = targetList?.map(i => getItemField(i));
  return sourceList?.filter(i => !existValues?.includes(getItemField(i)));
}

export function isUnid(value: string): boolean {
  return value && isString(value) && value.length && !!value.match(REG_EXP.unid);
}

export function isUuid(value: string): boolean {
  return value && isString(value) && value.length && validateUuid(value);
}

export function generateUuid(): string {
  return uuidV4();
}

export function getProfileImageUrl(notesName: string, width?: number): string {
  const params = getCloudfrontParams(`profiles/${btoa(notesName)}`, {width});
  return `${environment.cloudfrontUrl}/${encodeObject(params)}`;
}

export function getCloudfrontParams(key: string, resize?: IResize): ICloudfrontParams {
  return {
    key,
    edits: {resize},
    bucket: 'ibricks',
    timestamp: sessionStorage.getItem('timestamp') || null
  };
}

export function categoriesExternalFilter(field?: string): IExternalFilter {
  return externalFilter('COMMON.CATEGORIES', field || FieldEnum.Categories, []);
}

export function externalFilter(
  label: string,
  field: string,
  values: any[],
  defaultValue?: any,
  setValue?: any,
  hideAllOption?: boolean,
  isRequest?: boolean
): IExternalFilter {
  // TODO create class instead
  return {label, field, values, defaultValue, setValue, hideAllOption, isRequest};
}

export function statusExternalFilter(
  defaultValue?: any,
  setValue?: any,
  hideAllOption?: boolean,
  values?: any[]
): IExternalFilter {
  // TODO create class instead
  return externalFilter('COMMON.STATUS', FieldEnum.Status, values || [], defaultValue, setValue, hideAllOption);
}

export async function base64toFile(base64: string): Promise<File> {
  const type = base64?.split(',')?.[0]?.match(/:(.*?);/)?.[1];
  return fetch(base64)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], type?.replace('/', '.'), {type}));
}

export function fileToDataUrl(file: File): Promise<string | null> {
  return new Promise(resolve => {
    const fr = new FileReader();
    fr.onloadend = async () => resolve(fr?.result?.toString());
    fr.onerror = () => resolve(null);
    fr.readAsDataURL(file);
  });
}

export function objectsToOptions(data?: Array<IIBricksEntity | CategoryOption>): IOption[] {
  return data?.length ? data.map(i => objToOption(i)) : [];
}

export function objToOption(obj: any, valueField?: string): IOption {
  const label = obj?.subject || obj?.label;
  const value = valueField ? obj[valueField] : obj?.unid || obj?.value;
  return {label, value};
}

export function isEmailMenuItemAvailable(data: any): boolean {
  return !!data?.mailAddress?.length || !!data?.email?.length;
}

export function removeCookies(): void {
  const expires = '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=';
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase = encodeURIComponent(cookie.split(';')[0].split('=')[0]) + expires + d.join('.') + ' ;path=';
      const p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

export function checkLanguage(lang: string): string | null {
  const language = lang?.length ? lang.toLowerCase() : null;
  return language && ([ValueEnum.En, ValueEnum.No] as string[]).includes(language) ? language : null;
}

export function openInNewTab(url: string): void {
  if (url?.length) {
    window.open(url, '_blank');
  }
}

export function openInSameTab(url: string): void {
  if (url?.length) {
    window.open(url, '_self');
  }
}

export function patchReaders(
  formGroup: AbstractControl,
  list: IOption[],
  profile: IProfile,
  defaultReadLevel?: IOption
): void {
  const personalReadAccess =
    profile?.companySettings?.personalReadAccess && profile?.defaultReadLevel?.value ? profile?.defaultReadLevel : null;
  const readLevel = convertReadLevel(
    personalReadAccess || defaultReadLevel || profile?.companySettings?.defaultReadLevel
  );
  if (readLevel?.value) {
    const readers = [readLevel];
    patchTargetValueByFieldName(formGroup as UntypedFormGroup, list, {readers}, FieldEnum.Readers);
  }
}

function convertReadLevel(readLevel: IOption): IOption {
  if (readLevel?.value) {
    if (readLevel.value !== ALL_READERS_ACCESS) {
      readLevel.value =
        readLevel.value.startsWith('[') && readLevel.value.endsWith(']') ? readLevel.value : `[${readLevel.value}]`;
    }
    return readLevel;
  } else {
    return null;
  }
}

export function appendChild(elem: any): Promise<boolean> {
  return new Promise<boolean>(res => {
    if (elem.readyState) {
      // IE
      elem.onreadystatechange = () => {
        if (['loaded', 'complete'].includes(elem.readyState)) {
          elem.onreadystatechange = null;
          res(true);
        } else {
          res(false);
        }
      };
    } else {
      // Others
      elem.onload = () => res(true);
      elem.onerror = () => res(false);
    }

    document.head.appendChild(elem);
  });
}

export function removePhoneSpaces<T>(data: ICompany | IContact): T {
  const fields = [FieldEnum.OfficePhoneNumber, FieldEnum.CellPhoneNumber, FieldEnum.PhoneNumber];
  fields.forEach(i => {
    if (isString(data[i])) {
      data[i] = removeSpaces(data[i]);
    }
  });
  return data as T;
}

export function mergeDeep<T>(target: T, obj: Partial<T>): T {
  for (const key of Object.keys(obj)) {
    if (!hasOwnProperty(target, key) || typeof obj[key] !== 'object') {
      target[key] = obj[key];
    } else {
      mergeDeep(target[key], obj[key]);
    }
  }
  return target;
}

export function getTaskParentOriginUrl(task: ITask, isMyTasks?: boolean): IEntityUrl {
  const modules = [RouteEnum.Incidents.toUpperCase(), RouteEnum.QaDocuments.toUpperCase()];

  if (
    task?.taskType === TaskTypeEnum.Approval &&
    modules.includes(task?.parentOrigin?.module) &&
    task?.parentOrigin?.unid
  ) {
    return {
      url: task.parentOrigin.module.toLowerCase(),
      id: task.parentOrigin.unid
    };
  }

  return {
    url: isMyTasks ? `${RouteEnum.Tasks}/${RouteEnum.My}` : RouteEnum.Tasks
  };
}

// Keep commented utilities that can be useful in the future.

// export function arrayToDictionary(arr: any[]): any {
//   return arr ? arr.reduce((obj, cur) => ({ ...obj, [cur.unid || cur.value]: cur }), {}) : {};
// }

// export function uniqValues(arr: string[]): string[] {
//   return arr?.length ? arr.filter((value, index, self) => self.indexOf(value) === index) : [];
// }

// export function hasMatchInTwoArraysOfStrings(arr1: string[], arr2: string[]): boolean {
//   const mapped = arr2?.map(j => j?.toLowerCase());
//   const filtered = arr1.filter(i => mapped?.includes(i?.toLowerCase()));
//   return !!filtered?.length;
// }

// export function compareObjects(objOne: object, objTwo: object): boolean {
//   return JSON.stringify(objOne) === JSON.stringify(objTwo);
// }
