import {IEnvironment} from 'utilities';

export const environment: IEnvironment = {
  production: true,
  ibricksApiV1: 'https://api.ibricks.no/v1',
  ibricksApiV2: 'https://api.ibricks.no/v2',
  ibricksApiV3: 'https://api.ibricks.no/v3',
  ibricksApiV4: 'api2-dev.ibricks.no', // TODO add prod url
  ibricksApi: 'https://api.ibricks.no',
  ibricksImages: 'https://image.ibricks.no',
  login: 'https://api.ibricks.no/names.nsf?Login',
  logout: 'https://api.ibricks.no/ibricks/api-v3.nsf?logout',
  upload: 'https://functions.ibricks.no/.netlify/functions/upload',
  imageUpload: 'https://functions.ibricks.no/.netlify/functions/imageupload',
  invalidateCloudfrontCache: 'https://functions.ibricks.no/.netlify/functions/invalidate-cache',
  cloudfrontUrl: 'https://d2n98k53uqkrwy.cloudfront.net',
  msGraphApi: 'https://graph.microsoft.com/v1.0',
  azureApi: 'https://prod-149.westeurope.logic.azure.com:443',
  contentBuilderUrl: 'https://contentbuilder.ibricks.no',
  translateApi: 'https://translation.googleapis.com/language/translate',
  username: '', // see README.md
  password: '' // see README.md
};
