import {IOption} from '../interfaces';

export enum TypeEnum {
  Standard = 'STANDARD',
  MainOffice = 'MAIN_OFFICE',
  SubOffice = 'SUB_OFFICE',
  Main = 'MAIN',
  Response = 'RESPONSE',
  Sub = 'SUB',
  Number = 'number',
  String = 'string'
}

export enum IncidentTypeEnum {
  Deviation = 'TYPE_DEVIATION',
  Proposal = 'TYPE_PROPOSAL',
  Positive = 'TYPE_POSITIVE'
}

export enum WidgetTypeEnum {
  projectList = 'PROJECT-LIST',
  recentDocuments = 'RECENT-DOCUMENTS',
  shortcuts = 'SHORTCUTS',
  preview = 'PREVIEW',
  imageCarousel = 'IMAGE-CAROUSEL',
  statistics = 'INCIDENT-STATS',
  status = 'STATUS'
}

export enum WidgetStatisticTypeEnum {
  HMS = 'hse',
  QUALITY = 'quality'
}

export enum CompetenceTypeEnum {
  Proof = 'PROOF',
  Course = 'COURSE',
  Education = 'EDUCATION',
  Certificate = 'CERTIFICATE'
}

export enum IncidentWhyTypeEnum {
  PERSONRELATED = '1',
  THIRDPARTY = '2',
  OTHER = '3'
}

export enum TaskTypeEnum {
  Normal = 'NORMAL',
  ReadConfirmation = 'READCONFIRMATION',
  Forms = 'FORMS',
  Approval = 'APPROVAL'
}

export enum TaskConfirmationReadDataEnum {
  IncidentAttachment = 'INCIDENTATTACHMENT',
  TaskAttachment = 'TASKATTACHMENT',
  TaskBody = 'TASKBODY',
  Document = 'DOCUMENT'
}

export function getCompetenceTemplateTypes(): IOption[] {
  return Object.values(CompetenceTypeEnum).map(value => ({label: `TYPES.${value}`, value}));
}
