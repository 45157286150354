export enum FieldEnum {
  // Components = 'components',
  // ModifiedBy = 'modifiedBy',
  // Years = 'years',
  Name = 'name',
  Categories = 'categories',
  Tags = 'tags',
  Organization = 'organization',
  Template = 'template',
  ParentOffice = 'parentOffice',
  MailAddress = 'mailAddress',
  Email = 'email',
  OurContact = 'ourContact',
  Readers = 'readers',
  Project = 'project', // TODO create EntityTypeEnum for schema/correspondence
  Company = 'company',
  User = 'user',
  Fallback = 'fallback',
  UserList = 'userList',
  Companies = 'companies',
  Person = 'person',
  Editors = 'editors',
  Responsible = 'responsible',
  From = 'from',
  Department = 'department',
  Superior = 'superior',
  Why = 'why',
  What = 'what',
  CompanyName = 'companyName',
  CompanyNames = 'companyNames',
  City = 'city',
  Zip = 'zip',
  StreetAddress = 'streetAddress',
  QaDocuments = 'qaDocuments',
  Proposal = 'proposal',
  WhyType = 'whyType',
  WhyTypes = 'whyTypes',
  DeviationType = 'deviationType',
  DeviationClass = 'deviationClass',
  RiskSum = 'riskSum',
  RiskConsequence = 'riskConsequence',
  RiskProbability = 'riskProbability',
  Year = 'year',
  Book = 'book',
  Books = 'books',
  Days = 'days',
  ParentData = 'parentData',
  Interval = 'interval',
  Members = 'members',
  Contact = 'contact',
  Contacts = 'contacts',
  ProjManager = 'projManager',
  ProjName = 'projName',
  ProjType = 'projType',
  ProjNum = 'projNum',
  Notify = 'notify',
  Workflow = 'workflow',
  Workflows = 'workflows',
  ReasonTypes = 'reasonTypes',
  RegReaders = 'regReaders',
  Options = 'options',
  Address = 'address',
  Country = 'country',
  Subject = 'subject',
  Subjects = 'subjects',
  Label = 'label',
  ProjPlace = 'projPlace',
  CompRef = 'compRef',
  UserSettings = 'userSettings',
  ContactData = 'contactData',
  CellPhoneNumber = 'cellPhoneNumber',
  PhoneNumber = 'phoneNumber',
  CompanySettings = 'companySettings',
  Access = 'access',
  Unid = 'unid',
  UnidList = 'unidList',
  Body = 'body',
  FileBased = 'fileBased',
  Attachment = 'attachment',
  AttachmentList = 'attachmentList',
  DocRef = 'docRef',
  Title = 'title',
  Icon = 'icon',
  TopColor = 'topColor',
  Type = 'type',
  Data = 'data',
  StartDate = 'startDate',
  DueDate = 'dueDate',
  Sale = 'sale',
  Purchase = 'purchase',
  ProjSubNum = 'projSubNum',
  DateStart = 'dateStart',
  DateEnd = 'dateEnd',
  Status = 'status',
  Parent = 'parent',
  Form = 'form',
  UseSubProjects = 'useSubProjects',
  ActionOnSite = 'actionOnSite',
  ActionOnSiteBody = 'actionOnSiteBody',
  IncidentType = 'incidentType',
  RepId = 'repId',
  Value = 'value',
  Id = 'id',
  ParentOrigin = 'parentOrigin',
  Module = 'module',
  ExternalNames = 'externalNames',
  FormData = 'formData',
  Assignee = 'assignee',
  Verifier = 'verifier',
  Approver = 'approver',
  Comment = 'comment',
  Author = 'author',
  Notification = 'notification',
  DocNumId = 'docNumId',
  DocNum = 'docNum',
  Lifespan = 'lifespan',
  Lifespans = 'lifespans',
  Level = 'level',
  DateExpire = 'dateExpire',
  Expire = 'expire',
  Links = 'links',
  Languages = 'languages',
  LanguageContent = 'languageContent',
  UrlId = 'urlId',
  Groups = 'groups',
  Group = 'group',
  DateNotice = 'dateNotice',
  RegNumber = 'regNumber',
  OfficePhoneNumber = 'officePhoneNumber',
  WebSite = 'webSite',
  OfficeType = 'officeType',
  TrainingDate = 'trainingDate',
  TrainingDuration = 'trainingDuration',
  Lifetime = 'lifetime',
  License = 'license',
  External = 'external',
  MailUser = 'mailUser',
  IncludeVerifier = 'includeVerifier',
  NotifyAuthor = 'notifyAuthor',
  BeforeAttachments = 'beforeAttachments',
  Date = 'date',
  DefaultReadLevel = 'defaultReadLevel',
  EnWebPages = 'enWebPages',
  ReadLevels = 'readLevels',
  TaskStatus = 'taskStatus',
  MembersExtra = 'membersExtra',
  CloudShare = 'cloudShare',
  Signature = 'signature',
  CurrentStatus = 'currentStatus',
  Created = 'created',
  Modified = 'modified',
  FolderUrl = 'folderUrl',
  DriveId = 'driveId',
  TemplateId = 'templateId',
  ShareId = 'shareId',
  DriveRecipients = 'driveRecipients',
  DocId = 'docId',
  NewsItems = 'newsItems',
  PubDate = 'pubDate',
  ByLine = 'byLine',
  FileName = 'fileName',
  Src = 'src',
  OriginalFileName = 'originalFileName',
  GpsPosition = 'gpsPosition',
  Height = 'height',
  Width = 'width',
  Size = 'size',
  DocDate = 'docDate',
  ReadConfirmation = 'readConfirmation',
  ReaderConfirmation = 'readerConfirmation',
  ReadData = 'readData',
  ReadDocument = 'readDocument',
  Files = 'files',
  File = 'file',
  DataUrl = 'dataUrl',
  TaskType = 'taskType',
  Url = 'url',
  ArticlePath = 'articlePath',
  NewsPath = 'newsPath',
  Filter = 'filter',
  LastName = 'lastName',
  FirstName = 'firstName',
  Senders = 'senders',
  Sender = 'sender',
  Recipients = 'recipients',
  SjaAction1 = 'sjaAction1',
  SjaAction2 = 'sjaAction2',
  Risks = 'risks',
  Risk = 'risk',
  Operation = 'operation',
  Action = 'action',
  RepeatData = 'repeatData',
  SkipRedDay = 'skipRedDay',
  StartTime = 'startTime',
  WeekDays = 'weekDays',
  DayOfMonth = 'dayOfMonth',
  Months = 'months',
  Order = 'order',
  OrderParsell = 'orderParsell',
  AfterAttachments = 'afterAttachments',
  AbsenceType = 'absenceType',
  OffWorkDays = 'offWorkDays',
  CostNok = 'costNok',
  Location = 'location',
  ResultBody = 'resultBody',
  HseType = 'hseType',
  JobTitle = 'jobTitle',
  Votes = 'votes',
  Feedbacks = 'feedbacks',
  ReadAccess = 'readAccess',
  UploadProfileImage = 'uploadProfileImage',
  PersonalReadAccess = 'personalReadAccess',
  DocType = 'docType',
  OptIn = 'optIn',
  Receivers = 'receivers',
  Resume = 'resume',
  ProjectsCreateMenu = 'projectsCreateMenu',
  DocumentsCreateMenu = 'documentsCreateMenu',
  EmpId = 'empId',
  Position = 'position',
  ObservationTypes = 'observationTypes',
  PageType = 'pageType',
  Html = 'html',
  MetaSubject = 'metaSubject',
  MetaDescription = 'metaDescription',
  DataSets = 'dataSets',
  Sort = 'sort',
  SortDescending = 'sortDescending',
  GridState = 'gridState',
  Link = 'link',
  Image = 'image',
  Description = 'description',
  Required = 'required',
  Database = 'database',
  Count = 'count',
  DefaultFilter = 'defaultFilter',
  DefaultType = 'defaultType',
  HideDocTitle = 'hideDocTitle',
  UseAsColumnDefinition = 'useAsColumnDefinition',
  Min = 'min',
  Max = 'max',
  RowsCount = 'rowsCount',
  ProjectType = 'projectType',
  ProjectStatus = 'projectStatus',
  ProjectTag = 'projectTag',
  Employee = 'employee',
  CompanyTag = 'companyTag',
  Default = 'default',
  CustomClassName = 'customClassName',
  Visibility = 'visibility',
  ConditionField = 'conditionField',
  Condition = 'condition',
  ConditionValue0 = 'conditionValue0',
  ConditionValue1 = 'conditionValue1',
  Log = 'log',
  UserOnly = 'useronly',
  FullName = 'fullName',
  Gender = 'gender',
  DateBirth = 'dateBirth',
  FirstWorkDay = 'firstWorkDay',
  LastWorkDay = 'lastWorkDay',
  OtherPhoneNumber = 'otherPhoneNumber',
  Relationship = 'relationship',
  ParentDocNum = 'parentDocNum',
  FormsMenu = 'formsMenu',
  NewActionLinks = 'newActionLinks',
  SjaQaDocId = 'sjaQaDocId',
  SjaFormId = 'sjaFormId',
  FormId = 'formId',
  SystemSchema = 'systemSchema',
  DefaultStatus = 'defaultStatus',
  SubProjectUse = 'subProjectUse',
  SubProjectTabLabel = 'subProjectTabLabel',
  SubProjectButtonLabel = 'subProjectButtonLabel',
  SharepointFolder = 'sharepointFolder',
  SharepointUrl = 'sharepointUrl',
  QaManual = 'qaManual',
  QaPlan = 'qaPlan',
  HseManual = 'hseManual',
  Dispatches = 'dispatches',
  Message = 'message',
  Reasons = 'reasons',
  RiskType = 'riskType',
  Consequences = 'consequences',
  RiskLevelBefore = 'riskLevelBefore',
  RiskLevelAfter = 'riskLevelAfter',
  Actions = 'actions',
  NewContact = 'newContact',
  NewCompany = 'newCompany',
  AddTags = 'addTags',
  AddCellPhone = 'addCellPhone',
  AddMailAddress = 'addMailAddress',
  DepartmentApproval = 'departmentApproval',
  CloseOnApproval = 'closeOnApproval',
  IncidentsShortcut = 'incidentsShortcut',
  PositiveIncidents = 'positiveIncidents',
  PositiveIncidentsShortcut = 'positiveIncidentsShortcut',
  Proposals = 'proposals',
  ProposalsShortcut = 'proposalsShortcut',
  AnonymousUse = 'anonymousUse',
  Visible = 'visible',
  ColumnDefinition = 'columnDefinition',
  ColorStyle = 'colorStyle',
  AllWords = 'allwords',
  Query = 'query',
  DbMax = 'dbmax',
  DbList = 'dblist',
  DaysNotification = 'daysNotification',
  ContId = 'contId',
  MailList = 'mailList',
  SmsList = 'smsList',
  InternetAddress = 'internetAddress',
  Confirm = 'confirm',
  Password = 'password',
  Key = 'key',
  Relatives = 'relatives',
  HeaderImage = 'headerImage',
  FooterImage = 'footerImage',
  SendgridId = 'sendgridId',
  ExternalFilter = 'externalFilter',
  RequestFilter = 'requestFilter',
  QuickFilter = 'quickFilter',
  Username = 'username',
  Include = 'include',
  Category = 'category',
  Admin = 'admin',
  CommentFeedback = 'commentFeedback',
  MailSignature = 'mailSignature',
  DefaultOrganization = 'defaultOrganization',
  Language = 'language',
  Quantity = 'quantity',
  RememberMe = 'rememberMe',
  Pinned = 'pinned',
  Version = 'version',
  StoreName = 'storeName',
  ErrorMessage = 'errorMessage'
}
